import { styled } from "@enerbit/base"


export const StyleCardDocuments = styled("div")({display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF", // Fondo blanco
    borderRadius: "8px",        // Bordes redondeados
    padding: "16px",            // Espaciado interno
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Sombra
    gap: "12px",      })
