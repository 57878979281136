import { Box, Grid, Typography, styled } from "@enerbit/base";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { libraryOptions } from "../../../const/libraryJson";
import { RootState } from "../../../store/libraryStore";
import OptionsMenu from "./OptionsMenu";
import { StyleCardDocuments } from "../../../common/components/StyleCardDocuments";

interface Props {
	option: string;
}

const ConsultResults = ({ option }: Props) => {
	const data = useSelector((state: RootState) => state.library.data);
	data.forEach((item, index) => {
		console.log(`Elemento ${index}:`, item);
		console.log("Data:", data);
		console.log("Option:", option);

	});
	const fileName = useSelector((state: RootState) => state.library.fileName);

	const contentRef = useRef<HTMLDivElement>(null);
	const [isOverflowing, setIsOverflowing] = useState<boolean>(false);

	useEffect(() => {
		if (contentRef.current?.scrollHeight && contentRef.current?.clientHeight) {
			if (contentRef.current.scrollHeight > contentRef.current.clientHeight) {
				setIsOverflowing(true);
			} else {
				setIsOverflowing(false);
			}
		}
	}, []);

	const fileNames: Record<string, string> = {
		active_import: "Certificado Activa - Importada",
		active_export: "Certificado Activa - Exportada",
		reactive_import: "Certificado Reactiva - Importada",
		reactive_export: "Certificado Reactiva -  Exportada",
		active_export_import: "Certificado Activa Exportada - Importada",
		reactive_export_import: "Certificado Reactiva Exportada - Importada",
	};

	return (
		<>
			{option && (
				<Typography
					color={"primary"}
					sx={{ fontSize: "16px", fontWeight: 700, marginBottom: "12px" }}
				>
					{fileName}
				</Typography>
			)}
			<ListContainer
				ref={contentRef}
				style={{ overflowY: isOverflowing ? "scroll" : "hidden" }}
			>
				{option && option === "ttCompliances" && (
					<>
						{/* Verificamos si `data` es un array válido */}
						{Array.isArray(data) && data.length > 0 ? (
							[...data] // Creamos una copia del arreglo original
								.sort((a: any, b: any) => {
									// Convertimos las fechas a formato Date para comparar
									const dateA = new Date(a.creationDate.split("-").reverse().join("-"));
									const dateB = new Date(b.creationDate.split("-").reverse().join("-"));

									// Ordenamos en orden descendente (más reciente primero)
									return dateB.getTime() - dateA.getTime();
								})
								.map((item: any, index: number) => (
									<div key={index}>
										<Container>

											{/* Renderizamos la información del objeto */}
											<Box mt={1} mb={2}>
												<Grid container rowSpacing={5} columnSpacing={6}>
													{/* Verificamos si `extra` existe y es un array */}
													{Array.isArray(item.extra) &&
														item.extra.map((e: any, idx: number) => (
															<Grid item key={idx}>
																<Typography variant="body1" color="neutral.700">
																	{`${e.name}:`}
																</Typography>
																<Typography
																	variant="body1"
																	color="neutral.700"
																	fontWeight={700}
																>
																	{e.value}
																</Typography>
															</Grid>
														))}
												</Grid>
											</Box>

											<ActionsContainer>
												<StyleCardDocuments>
													<FileContainer key={index}>
														<PdfIcon>PDF</PdfIcon>
														<Box>
															<Typography fontWeight={700} variant="subtitle1">
																{`${item.extra[2].value}.pdf`}
															</Typography>
															<Typography
																sx={{
																	fontWeight: "400",
																	color: "#667085",
																	fontSize: "14px",
																	paddingLeft: "10px",
																}}
															>
																{`Fecha de creación: ${item.creationDate}`}
															</Typography>
														</Box>
														<MenuContainer>
															<OptionsMenu
																file={item.authUrl}


															/>
														</MenuContainer>
													</FileContainer>
												</StyleCardDocuments>
											</ActionsContainer>
										</Container>
									</div>
								))
						) : (
							<Typography variant="body1" color="text.secondary">
								No hay datos disponibles para esta opción.
							</Typography>
						)}
					</>
				)}



				{option && option === "tcCompliances" && (
					<>
						{/* Verificamos si `data` es un array válido */}
						{Array.isArray(data) && data.length > 0 ? (
							[...data] // Creamos una copia del arreglo original
								.sort((a: any, b: any) => {
									// Convertimos las fechas a formato Date para comparar
									const dateA = new Date(a.creationDate.split("-").reverse().join("-"));
									const dateB = new Date(b.creationDate.split("-").reverse().join("-"));

									// Ordenamos en orden descendente (más reciente primero)
									return dateB.getTime() - dateA.getTime();
								})
								.map((item: any, index: number) => (
									<div key={index}>
										<Container>
											{/* Mostramos información principal */}
											<Typography variant="subtitle1" color="primary.main">
												{fileName}
											</Typography>

											{/* Renderizamos la información del objeto */}
											<Box mt={1} mb={2}>
												<Grid container rowSpacing={5} columnSpacing={6}>
													{/* Verificamos si `extra` existe y es un array */}
													{Array.isArray(item.extra) &&
														item.extra.map((e: any, idx: number) => (
															<Grid item key={idx}>
																<Typography variant="body1" color="neutral.700">
																	{`${e.name}:`}
																</Typography>
																<Typography
																	variant="body1"
																	color="neutral.700"
																	fontWeight={700}
																>
																	{e.value}
																</Typography>
															</Grid>
														))}
												</Grid>
											</Box>

											{/* Renderizamos acciones */}
											<ActionsContainer>
												<Box sx={{
													display: "flex",
													alignItems: "center",
													backgroundColor: "#FFFFFF", // Fondo blanco
													borderRadius: "8px",        // Bordes redondeados
													padding: "16px",            // Espaciado interno
													boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Sombra
													gap: "12px",                // Espaciado entre elementos
												}}
												>
													<FileContainer key={index}>
														<PdfIcon>PDF</PdfIcon>
														<Box>
															<Typography fontWeight={700} variant="subtitle1">
																{`${item.extra[2].value}.pdf`}
															</Typography>
															<Typography
																sx={{
																	fontWeight: "400",
																	color: "#667085",
																	fontSize: "14px",
																	paddingLeft: "10px",
																}}
															>
																{`Fecha de creación: ${item.creationDate}`}
															</Typography>
														</Box>
														<MenuContainer>
															<OptionsMenu
																file={item.authUrl}

															/>
														</MenuContainer>
													</FileContainer>
												</Box>
											</ActionsContainer>
										</Container>
									</div>
								))
						) : (
							<Typography variant="body1" color="text.secondary">
								No hay datos disponibles para esta opción.
							</Typography>
						)}
					</>
				)}

				{option && option !== "CalibrationCertificateSingle" && option !== "tcCompliances" && option !== "ttCompliances" && (
					<>
						{data.map((item, index) => (
							<div key={index}>
								<Container>
									{item.extra && (
										<Box mt={1} mb={2}>
											<Grid container rowSpacing={5} columnSpacing={6}>
												{item.extra.map((e: any, idx: number) => (
													<Grid item key={idx}>
														<Typography variant="body1" color="neutral.700">
															{`${e.name}:`}
														</Typography>
														<Typography variant="body1" color="neutral.700" fontWeight={700}>
															{e.value}
														</Typography>
													</Grid>
												))}
											</Grid>
										</Box>
									)}
									<ActionsContainer>
									{item.files  ? (
										<Grid container spacing={2}>
											{item.files.map((file: any, idx: number) => (
												<Grid item xs={12} sm={6} key={idx}>
													<StyleCardDocuments>
													<FileContainer>
														<PdfIcon>PDF</PdfIcon>
														<Box>
															<Typography fontWeight={700} variant="subtitle1">
																{`${file.name}.pdf`}
															</Typography>
															<Typography
																sx={{
																	fontWeight: "400",
																	color: "#667085",
																	fontSize: "14px",
																	paddingLeft: "10px",
																}}
															>
																{`Fecha de creación: ${item.creationDate}`}
															</Typography>
														</Box>
														<MenuContainer>
															<OptionsMenu file={file.url} />
														</MenuContainer>
													</FileContainer>
													</StyleCardDocuments>
												</Grid>
											))}
										</Grid>
										) : (
													<StyleCardDocuments>
													<FileContainer>
														<PdfIcon>PDF</PdfIcon>
														<Box>
															<Typography fontWeight={700} variant="subtitle1">
																{`${item.extra[0].value}.pdf`}
															</Typography>
															<Typography
																sx={{
																	fontWeight: "400",
																	color: "#667085",
																	fontSize: "14px",
																	paddingLeft: "10px",
																}}
															>
																{`Fecha de creación: ${item.creationDate}`}
															</Typography>
														</Box>
														<MenuContainer>
															<OptionsMenu file={item.authUrl} />
														</MenuContainer>
													</FileContainer>
													</StyleCardDocuments>
										)}
									</ActionsContainer>
								</Container>
							</div>
						))}
					</>
				)}

				{option !== "tcCompliances" &&
					option !== "ttCompliances" &&
					option !== "meterCalibrationCertificate" &&
					option === "CalibrationCertificateSingle" && (
						<>
							{data.map((item, index) => (
								<div key={index}>
									<Container>
										<Box mb={1}>
											{item.extra && (
												<Box mt={1} mb={2}>
													<Grid container rowSpacing={5} columnSpacing={6}>
														{item.extra.map((e: any, idx: number) => (
															<Grid item key={idx}>
																<Typography variant="body1" color="neutral.700">
																	{`${e.name}:`}
																</Typography>
																<Typography
																	variant="body1"
																	color="neutral.700"
																	fontWeight={700}
																>
																	{e.value}
																</Typography>
															</Grid>
														))}
														<Grid item>
															<Typography variant="body1" color="neutral.700">
																{`Fecha de creación:`}
															</Typography>
															<Typography
																variant="body1"
																color="neutral.700"
																fontWeight={700}
															>
																{item.creationDate}
															</Typography>
														</Grid>
													</Grid>
												</Box>
											)}
										</Box>

										{/* Verificar si item.files existe y no está vacío */}
										{item.files && item.files.length > 0 ? (
											<Grid container spacing={2}>
												{item.files.map((f: any, i: number) => (
													<Grid item xs={12} sm={6} key={i}>
														<ActionsContainer>
															<FileContainer>
																<FileIcon>PDF</FileIcon>
																<Box>
																	<Typography fontWeight={700} variant="subtitle2">
																		{`${fileNames[f.name]}.pdf`}
																	</Typography>
																</Box>
															</FileContainer>
															<MenuContainer>
																<OptionsMenu file={f.url} />
															</MenuContainer>
														</ActionsContainer>
													</Grid>
												))}
											</Grid>
										) : (
											<Grid container spacing={2}>
												<Grid item xs={12} sm={6}>
													<ActionsContainer>
													<StyleCardDocuments>
														<FileContainer>
															<FileIcon>PDF</FileIcon>
															<Box>
																<Typography fontWeight={700} variant="subtitle2">
																	{`${fileNames[item.name]}.pdf`}
																</Typography>
															</Box>
														</FileContainer>
														<MenuContainer>
															<OptionsMenu file={item.authUrl} />
														</MenuContainer>
														</StyleCardDocuments>
													</ActionsContainer>
												</Grid>


											</Grid>
										)}
									</Container>
								</div>
							))}
						</>
					)}

			</ListContainer>
		</>
	);
};

export default ConsultResults;

const Container = styled("div")`
  width: 100%;
  background-color: #f2f4f7;
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 10px;
`;

const PdfIcon = styled("div")`
  width: 45px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f04438;
  border-radius: 4px;
  color: white;
`;

const FileIcon = styled("div")`
  width: 40px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f04438;
  border-radius: 4px;
  color: white;
`;

const FileContainer = styled("div")`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const ActionsContainer = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const MenuContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ListContainer = styled("div")`
  height: 270px;
  margin-bottom: 100px;
  padding-right: 10px;
`;
