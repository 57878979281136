import { useEffect, useState } from "react";
import {
  Box,
  MenuItem,
  Select,
  Typography,
  styled,
  CircularProgress,
  Alert,
} from "@enerbit/base";
import {
  TLibraryDocument,
  libraryDocuments,
} from "../../models/LibraryConsult";
import ProductConformity from "./components/consultForms/ProductConformity";
import "../../styles/libraryPage.css";
import ConsultResults from "./components/ConsultResults";
import TechnicalEquipment from "./components/consultForms/TechnicalEquipment";
import { useSelector, useDispatch } from "react-redux";
import {
  AppDispatch,
  RootState,
  useAppSelector,
} from "../../store/libraryStore";
import {
  resetData,
  resetEmpty,
  resetError,
} from "../../store/slices/librarySlice/librarySlice";
import CalibrationCertificate from "./components/consultForms/CalibrationCertificate";
import { getDocsWithoutParams } from "../../store/slices/librarySlice/thunks";
import { GetSupplierPrevious } from "./components/consultForms/GetSupplierPrevious";
import { GetRecord } from "./components/consultForms/GetRecord";
import { resetTableAddress } from "../../store/slices/table-address/tableAddress";
// import RoutineTests from "./components/consultForms/RoutineTests";

const LibraryPage = () => {
  const isLoading = useSelector((state: RootState) => state.library.isLoading);
  const hasError = useSelector((state: RootState) => state.library.hasError);
  const isEmpty = useSelector((state: RootState) => state.library.isEmpty);
  const data = useSelector((state: RootState) => state.library.data);

  const dispatch = useDispatch<AppDispatch>();

  const [option, setOption] = useState<TLibraryDocument | "">("");

  const searchWithParams: TLibraryDocument[] = [
    "productMeterConformity",
    "terminalBlockCertificate",
    "wiresCertificate",
    "cellsCertificate",
    "technicalEquipment",
    "meterCalibrationCertificate",
    "tcCalibrationCertificate",
    "ttCalibrationCertificate",
    // "routinesTestCertificate",
    "recordDossierPreviousSupplier",
    "recordDossier",
  ];

  const consultForms: Record<TLibraryDocument, any> = {
    productMeterConformity: <ProductConformity option={option} />,
    terminalBlockCertificate: <ProductConformity option={option} />,
    wiresCertificate: <ProductConformity option={option} />,
    cellsCertificate: <ProductConformity option={option} />,
    technicalEquipment: <TechnicalEquipment option={option} />,
    meterCalibrationCertificate: <CalibrationCertificate option={option} />,
    tcCalibrationCertificate: <CalibrationCertificate option={option} />,
    ttCalibrationCertificate: <CalibrationCertificate option={option} />,
    // routinesTestCertificate: <RoutineTests option={option} />,
    policiesCGM: null,
    tlmSchema: null,
    opCommunications: null,
    infoProcess: null,
    meterValidationProcess: null,
    securityMechanism: null,
    dataSecurity: null,
    cgmDataProtection: null,
    anualReportCGM: null,
    maintenanceProcedure: null,
    recordDossierPreviousSupplier: <GetSupplierPrevious option={option} />,
    recordDossier: <GetRecord option={option} />,
  };

  const getDocs = async (type: TLibraryDocument) => {
    if (searchWithParams.includes(type)) return;
    const urls: Record<TLibraryDocument, string> = {
      policiesCGM:
        "/inventory-documentation/parameters-procedures-policies-cgm-certificate",
      tlmSchema:
        "/inventory-documentation/tlm-diagram-meter-communication-certificate",
      opCommunications:
        "/inventory-documentation/conditions-op-communications-meters-cgm-certificate",
      infoProcess:
        "/inventory-documentation/procedure-critique-information-certificate",
      meterValidationProcess:
        "/inventory-documentation/procedure-validation-data-read-from-meters",
      securityMechanism:
        "/inventory-documentation/last-read-log-file-sent-cgm-asic-certificate",
      dataSecurity:
        "/inventory-documentation/p-physical-computer-security-data-protection-certificate",
      cgmDataProtection:
        "/inventory-documentation/cgm-asic-data-transmission-procedure-certificate",
      anualReportCGM: "/inventory-documentation/cgm-annual-report-certificate",
      maintenanceProcedure:
        "/inventory-documentation/maintenance-procedure-measurements-certificate",
      recordDossierPreviousSupplier: "",
      productMeterConformity: "",
      terminalBlockCertificate: "",
      wiresCertificate: "",
      cellsCertificate: "",
      meterCalibrationCertificate: "",
      tcCalibrationCertificate: "",
      ttCalibrationCertificate: "",
      technicalEquipment: "",
      recordDossier: "",
    };

    const fileNames: Record<TLibraryDocument, string> = {
      policiesCGM: "Parámetros procedimientos y politicas CGM",
      tlmSchema: "Esquema TLM y comunicación medidores",
      opCommunications: "Condiciones op comunicaciones, medidores y cgm",
      infoProcess: "Procedimiento para la crítica de información",
      meterValidationProcess:
        "Procedimiento validación de datos leídos de los medidores",
      securityMechanism: "Mecanismo de protección, seguridad e integridad",
      dataSecurity:
        "Procedimiento seguridad física e informática para protección de datos",
      cgmDataProtection: "Procedimiento transmisión de datos CGM-ASIC",
      anualReportCGM: "Informe anual operación CGM",
      maintenanceProcedure: "Procedimiento de mantenimiento de la medida",
      recordDossierPreviousSupplier: "",
      productMeterConformity: "",
      terminalBlockCertificate: "",
      wiresCertificate: "",
      cellsCertificate: "",
      meterCalibrationCertificate: "",
      tcCalibrationCertificate: "",
      ttCalibrationCertificate: "",
      technicalEquipment: "",
      recordDossier: "",
    };

    dispatch(
      getDocsWithoutParams({ url: urls[type], fileName: fileNames[type] })
    );
  };

  useEffect(() => {
    dispatch(resetTableAddress());
  }, [option]);

  return (
    <Box>
      <Typography sx={{ fontSize: "28px", fontWeight: 700 }} color="primary">
        Documentación Cargue de documentos
      </Typography>
      <SearchContainer>
        <Typography
          sx={{ fontSize: "20px", fontWeight: 700, color: "#344054" }}
        >
          Búsqueda
        </Typography>
        <Box mt={"10px"} sx={{ width: "100%" }}>
          <Typography>Selecciona el documento a visualizar</Typography>
          <Select
            value={option}
            onChange={(e) => {
              const selectedOption = e.target.value;
              if (
                typeof selectedOption === "string" &&
                selectedOption in consultForms
              ) {
                setOption(selectedOption as TLibraryDocument);
                getDocs(selectedOption as TLibraryDocument);
                dispatch(resetData());
              }
            }}
            sx={{
              "& fieldset": {
                borderRadius: "12px",
                mt: "5px",
                height: "100%",
                border: "none",
              },
              width: "100%",
              backgroundColor: "#ffffff",
              borderRadius: "12px",
              border: "1px solid #d0d5dd",
              height: "48px",
            }}
          >
            {libraryDocuments.map((element, index) => (
              <MenuItem key={index} value={element.value}>
                {element.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {option && (
          <Box mt={"20px"} sx={{ width: "100%" }}>
            {consultForms[option]}
          </Box>
        )}
      </SearchContainer>
      {isLoading && (
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
      )}
      {!isLoading && data.length > 0 && (
        <Box mt={"25px"}>
          <ConsultResults option={option} />
        </Box>
      )}
      {hasError && (
        <Box mt={2}>
          <Alert onClose={() => dispatch(resetError())} severity="error">
            Lo sentimos, se ha presentado un error. Intenta de nuevo
          </Alert>
        </Box>
      )}
      {isEmpty && (
        <Box mt={2}>
          <Alert severity="info" onClose={() => dispatch(resetEmpty())}>
            No se encontraron documentos.
          </Alert>
        </Box>
      )}
    </Box>
  );
};

export default LibraryPage;

const SearchContainer = styled("div")`
  margin-top: 20px;
  width: 100%;
  background-color: #f2f4f7;
  border-radius: 16px;
  padding: 32px;
`;

const LoaderContainer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;
