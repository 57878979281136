import React from "react";
import { Box, Button, RemoveRedEyeOutlinedIcon, styled } from "@enerbit/base";

interface Props {
  file: string; // URL del archivo a previsualizar
}

const OptionsMenu = ({ file }: Props) => {
  return (
    <Box>
      <Button
        variant="contained"
        className="see-button"
        onClick={() => window.open(file, "_blank")}
        sx={{
          minWidth: "auto",   // Permite botones más compactos
          size: "small",      // Ajusta el tamaño del botón
        }}
      
      >
        <RemoveRedEyeOutlinedIcon />
      </Button>
    </Box>
  );
};

export default OptionsMenu;
