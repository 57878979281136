import {
    Button,
    Grid,
    SearchIcon,
    TextField,
    Typography,
    useFormik,
} from "@enerbit/base";
import { useDispatch } from "react-redux";
import { SerialValidation } from "../../../../const/validations";
import { AppDispatch } from "../../../../store/libraryStore";
import {
    getDocsByModelAndBrand,
} from "../../../../store/slices/librarySlice/thunks";

interface Props {
    option: string;
}

const TtComplianceCertificate = ({ option }: Props) => {
    const formik = useFormik({
        initialValues: { model: "", brand: "" },
        validationSchema: SerialValidation,
        onSubmit: () => {
            return;
        },
    });

    const dispatch = useDispatch<AppDispatch>();

    const fetchUrls: Record<string, string> = {
        ttComplianceCertificate:
            "/inventory-documentation/tt-compliances-certificate",
    };

    const fileNames: Record<string, string> = {
        ttCompliances: "Certificado de conformidad TT",
    };


    const handleSearch = () => {
        // Parámetros planos en el mismo nivel
        const params = {
            model: formik.values.model,
            brand: formik.values.brand,
            fileName: fileNames[option],
            url: "/inventory-documentation/tt-compliances-certificate",
            option: option,
        };
        dispatch(getDocsByModelAndBrand(params));
        resetForm();
    };

    const resetForm = () => {
        formik.setValues({ model: "", brand: "" });
    };

    return (
        <form>
            <Grid container columnSpacing={2}>
                <Grid item xs={5.5}>
                    <Typography mb={1} variant='body2'>
                        Marca
                    </Typography>
                    <TextField
                        placeholder='Escribe aquí la marca'
                        name='brand'
                        id='brand'
                        value={formik.values.brand}
                        onChange={(event) => {
                            formik.handleChange(event);
                        }}
                        fullWidth
                        error={Boolean(formik.errors.brand) && true}
                        helperText={formik.errors.brand}
                        InputProps={{
                            sx: { padding: "14px 0", height: "100%" },
                        }}
                        sx={{
                            "& fieldset": { borderRadius: "12px", mt: "5px", height: "100%", border: "none" },
                            width: "100%",
                            backgroundColor: "#ffffff",
                            borderRadius: "12px",
                            border: "1px solid #d0d5dd",
                            height: "48px",
                        }}
                    />
                </Grid>

                {/* Campo para la marca */}
                <Grid item xs={5.5}>
                    <Typography mb={1} variant='body2'>
                        Modelo
                    </Typography>
                    <TextField
                        fullWidth
                        name='model'
                        id='model'
                        placeholder='Escribe aquí el modelo'
                        value={formik.values.model}
                        onChange={(event) => {
                            formik.handleChange(event);
                        }}
                        error={Boolean(formik.errors.model) && true}
                        helperText={formik.errors.model}
                        InputProps={{ sx: { padding: "14px 0", height: "100%" } }}
                        sx={{
                            "& fieldset": { border: "none" },
                            width: "100%",
                            backgroundColor: "#ffffff",
                            borderRadius: "12px",
                            border: "1px solid #d0d5dd",
                            height: "48px",
                        }}
                    />
                </Grid>

                {/* Botón de búsqueda */}
                <Grid item xs={1} sx={{ display: "flex", alignItems: "center" }}>
                    <Button
                        fullWidth
                        sx={{ minWidth: "unset", mt: 1.5 }}
                        variant="contained"
                        onClick={(e) => {
                            e.preventDefault();
                            handleSearch();
                        }}
                    >
                        <SearchIcon />
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default TtComplianceCertificate;
