import { createSlice } from "@reduxjs/toolkit";
import { LibraryState } from "../../../models/LibraryConsult";
import {
  getDocsByBrand,
  getDocsByBrandAndGauge,
  getDocsByModelAndBrand,
  getDocsBySerialNumber,
  getDocsWithoutParams,
  getMeterCalibrationCertificate,
  getWireTechnicalSheet,
} from "./thunks";

const initialState: LibraryState = {
  data: [],
  isLoading: false,
  isEmpty: false,
  hasError: false,
  fileName: "",
};

export const librarySlice = createSlice({
  name: "libraryState",
  initialState,
  reducers: {
    resetData(state) {
      state.data = [];
    },
    setFileName(state, { payload }) {
      state.fileName = payload;
    },
    resetEmpty(state) {
      state.isEmpty = false;
    },
    resetError(state) {
      state.hasError = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(getWireTechnicalSheet.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
      state.isEmpty = false;
    });
    builder.addCase(getWireTechnicalSheet.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isEmpty = payload.length === 0;
      state.isLoading = false;
      state.hasError = false;
    });
    builder.addCase(getWireTechnicalSheet.rejected, (state) => {
      state.hasError = true;
      state.isLoading = false;
      state.isEmpty = false;
    });
    builder.addCase(getDocsByModelAndBrand.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
      state.isEmpty = false;
    });
    builder.addCase(getDocsByModelAndBrand.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isEmpty = payload.length === 0;
      state.isLoading = false;
      state.hasError = false;
    });
    builder.addCase(getDocsByModelAndBrand.rejected, (state) => {
      state.hasError = true;
      state.isLoading = false;
      state.isEmpty = false;
    });
    builder.addCase(getDocsByBrand.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
      state.isEmpty = false;
    });
    builder.addCase(getDocsByBrand.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isEmpty = payload.length === 0;
      state.isLoading = false;
      state.hasError = false;
    });
    builder.addCase(getDocsByBrand.rejected, (state) => {
      state.hasError = true;
      state.isLoading = false;
      state.isEmpty = false;
    });
    builder.addCase(getDocsByBrandAndGauge.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
      state.isEmpty = false;
    });
    builder.addCase(getDocsByBrandAndGauge.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isEmpty = payload.length === 0;
      state.isLoading = false;
      state.hasError = false;
    });
    builder.addCase(getDocsByBrandAndGauge.rejected, (state) => {
      state.hasError = true;
      state.isLoading = false;
      state.isEmpty = false;
    });
    builder.addCase(getDocsBySerialNumber.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
      state.isEmpty = false;
    });
    builder.addCase(getDocsBySerialNumber.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isEmpty = payload.length === 0;
      state.isLoading = false;
      state.hasError = false;
    });
    builder.addCase(getDocsBySerialNumber.rejected, (state) => {
      state.hasError = true;
      state.isLoading = false;
      state.isEmpty = false;
    });
    builder.addCase(getDocsWithoutParams.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
      state.isEmpty = false;
    });
    builder.addCase(getDocsWithoutParams.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isEmpty = payload.length === 0;
      state.isLoading = false;
      state.hasError = false;
    });
    builder.addCase(getDocsWithoutParams.rejected, (state) => {
      state.hasError = true;
      state.isLoading = false;
      state.isEmpty = false;
    });
    builder.addCase(getMeterCalibrationCertificate.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
      state.isEmpty = false;
    });
    builder.addCase(getMeterCalibrationCertificate.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isEmpty = payload.length === 0;
      state.isLoading = false;
      state.hasError = false;
    });
    builder.addCase(getMeterCalibrationCertificate.rejected, (state) => {
            state.hasError = true;
      state.isLoading = false;
      state.isEmpty = false;
    });
  },
});

export const { resetData, setFileName, resetEmpty, resetError } = librarySlice.actions;

export default librarySlice.reducer;
